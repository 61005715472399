import axios from "axios";
import store from '../store'

const instance = axios.create();
instance.defaults.baseURL = '/api';
instance.defaults.withCredentials = true;

//      Need for local build. Don`t touch.
// const apiUrl = 'http://localhost:5000';
// const instance = axios.create();
// instance.defaults.withCredentials = true;
// instance.defaults.baseURL = apiUrl + '/api';


instance.interceptors.response.use(function (response) {
    const data = response.data;
    // console.log(response);
    // console.log(data);
    const refreshActions = ['site_settings', 'user', 'personal_info', 'locale', 'fv', 'counters', 'user_wallet'];
    // const refreshActions = ['site_settings', 'user', 'personal_info', 'fv', 'counters', 'user_wallet'];

    switch (response.status) {
        case 200:
            Object.keys(data).forEach(field => {
                if (refreshActions.includes(field)) {
                    // if(field == 'site_settings') {
                    //     Object.keys(data[field]).forEach(key => {
                    //         if(typeof data[field][key] == "string") {
                    //             if(data[field][key].includes("/images/")) {
                    //                 data[field][key] = apiUrl + data[field][key];
                    //             }
                    //         }
                    //     })
                    // }
                    store.dispatch(`refresh_${field}`, data[field])
                }
            });
            break;
        default:
            window.location.reload();
            break;
    }

    return response;
}, function (error) {



    console.log(error)

    return Promise.reject(error);
});

export default instance