<template>
    <div class="operation-modal modal-bordered">
        <h1 class="operation-modal_title" v-html="$content(`form_${params.type}`)"></h1>
        <div class="operation-modal_body" v-if="!iframe_url">
            <div v-if="params.type === 'deposit'">
                <FormWrapper
                    @submitted="onDepositFormSubmit"
                    mb="15px"
                    repository="transactions"
                    :methods="{preload: 'getDepositForm', submit: 'onDeposit'}"
                    name="depositForm">

                    <template v-slot:depositForm_payment_system="props">
                        <div class="ps-selector" :class="{'ps-selector_opened': isOpened}">
                            <div class="ps-selector_body" @click="isOpened = !isOpened">
                                <div class="ps-selector_label" v-if="!!selected">
                                    <img :src="imageSelector(selected.img)">
                                </div>
                                <b-icon :icon="isOpened ? 'chevron-up' : 'chevron-down'"></b-icon>
                                <input
                                    :required="props.data.required"
                                    name="payment_system"
                                    :value="selected ? selected.value : ''"
                                    type="text"
                                    readonly>
                                <span>{{ selected ? selected.title : $content('ps_placeholder') }}</span>
                            </div>
                            <div class="ps-selector_list" v-if="isOpened">
                                <div
                                    class="ps-selector_item"
                                    v-for="ps in props.data.choices"
                                    @click="setPs(ps)">
                                    <div class="ps-selector_item_label">
                                        <img :src="imageSelector(ps.img)">
                                    </div>
                                    <span>{{ ps.title }}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:depositForm_amount="props">
                        <input
                            :value="props.data.value"
                            :required="props.data.required"
                            name="amount"
                            class="operation-modal_input"
                            :placeholder="$content('amount_placeholder')"
                            type="text" />
                    </template>

                    <template v-slot:submit>
                        <ProjectButton form="form_wrapper" type="submit" variant="app-light">
                            <div v-html="$content(`form_${params.type}_submit`)"></div>
                        </ProjectButton>
                    </template>

                </FormWrapper>
            </div>
            <div v-if="params.type === 'buy'">
                <FormWrapper
                    @submitted="onDepositFormSubmit"
                    name="buy"
                    mb="15px"
                    repository="shop"
                    :query="params.id"
                    :methods="{preload: 'psPurchaseForm', submit: 'psPurchaseFormSubmit'}">

                    <template v-slot:buy_payment_system="props">
                        <div class="ps-selector" :class="{'ps-selector_opened': isOpened}">
                            <div class="ps-selector_body" @click="isOpened = !isOpened">
                                <div class="ps-selector_label" v-if="!!selected">
                                    <img :src="imageSelector(selected.img)">
                                </div>
                                <b-icon :icon="isOpened ? 'chevron-up' : 'chevron-down'"></b-icon>
                                <input name="payment_system" :value="selected ? selected.value : ''" type="text" readonly>
                                <span>{{ selected ? selected.title : $content('ps_placeholder') }}</span>
                            </div>
                            <div class="ps-selector_list" v-if="isOpened">
                                <div
                                    class="ps-selector_item"
                                    v-for="ps in props.data.choices"
                                    @click="setPs(ps)">
                                    <div class="ps-selector_item_label">
                                        <img :src="imageSelector(ps.img)">
                                    </div>
                                    <span>{{ ps.title }}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:buy_amount="props">
                        <input
                            readonly
                            :required="props.data.required"
                            class="operation-modal_input"
                            name="amount"
                            type="text"
                            :value="props.data.value"
                            :placeholder="$content('detail_placeholder')" />
                    </template>

                    <template v-slot:buy_agreetment="props">
                        <div class="d-flex">
                            <b-form-checkbox
                                style="margin-left: 10px"
                                id="buy_rules"
                                name="agreetment"
                                :value="true"
                                :unchecked-value="false"></b-form-checkbox>
                            <span v-html="$content('buy_rules')"></span>
                        </div>
                    </template>

                    <template v-slot:submit>
                        <ProjectButton form="form_wrapper" type="submit" variant="app-light">
                            <div v-html="$content(`form_${params.type}_submit`)"></div>
                        </ProjectButton>
                    </template>

                </FormWrapper>
            </div>
            <div v-if="params.type === 'withdraw'">
                <FormWrapper
                    @submitted="onWithdraw"
                    name="withdrawForm"
                    mb="15px"
                    repository="transactions"
                    :methods="{preload: 'getWithdrawForm', submit: 'onWithdraw'}">

                <template v-slot:withdrawForm_payment_system="props">
                    <div class="ps-selector" :class="{'ps-selector_opened': isOpened}">
                        <div class="ps-selector_body" @click="isOpened = !isOpened">
                            <div class="ps-selector_label" v-if="!!selected">
                                <img :src="imageSelector(selected.img)">
                            </div>
                            <b-icon :icon="isOpened ? 'chevron-up' : 'chevron-down'"></b-icon>
                            <input name="payment_system" :value="selected ? selected.value : ''" type="text" readonly>
                            <span>{{ selected ? selected.title : $content('ps_placeholder') }}</span>
                        </div>
                        <div class="ps-selector_list" v-if="isOpened">
                            <div
                                class="ps-selector_item"
                                v-for="ps in props.data.choices"
                                @click="setPs(ps)">
                                <div class="ps-selector_item_label">
                                    <img :src="imageSelector(ps.img)">
                                </div>
                                <span>{{ ps.title }}</span>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:withdrawForm_withdraw_detail="props">
                    <input
                        :required="props.data.required"
                        class="operation-modal_input"
                        name="withdraw_detail"
                        type="text"
                        :value="props.data.value"
                        :placeholder="$content('detail_placeholder')" />
                </template>

                <template v-slot:withdrawForm_amount="props">
                    <input :required="props.data.required" :value="props.data.value" name="amount" class="operation-modal_input" :placeholder="$content('amount_placeholder')" type="text" />
                </template>

                <template v-slot:submit>
                    <ProjectButton form="form_wrapper" type="submit" variant="app-light">
                        <div v-html="$content(`form_${params.type}_submit`)"></div>
                    </ProjectButton>
                </template>
                </FormWrapper>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectButton from "../Buttons/ProjectButton";
import FormWrapper from "../Wrappers/FormWrapper";
import Submitter from "../Wrappers/Submitter";
export default {
    name: "Operation",
    components: {Submitter, FormWrapper, ProjectButton},
    data() {
        return {
            selected: null,
            isOpened: false,
            forms: {
                deposit: ''
            },
            iframe_url: ''
        }
    },
    methods: {
        onClose() {
          console.log("onClose")
            this.$root.$emit('balanceOperationFinished');
            this.$modal.close();
        },
        onWithdraw(e) {
            if (e.status === 'redirect') {
                this.onClose();
            }
        },
        onDepositFormSubmit(e) {
            if (e.status === 'success') {
                window.location.href = e.url.url;
            }
        },
        setPs(ps) {
            this.selected = ps;
            this.isOpened = false;
        },
        messageHandler(e) {
          // console.log("messageHandler")
          // console.log(e);
            this.onClose();
        }
    },
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        },
        ps() {
            return this.$store.getters.static.ps
        }
    },
    created() {
        // window.addEventListener("message", (e)=>{this.messageHandler(e)}, false);
    }
}
</script>

<style lang="scss" scoped>
    $inputHeight: 46px;
    $inputColor: #f6f6f6;

    .ps-selector {
        height: $inputHeight;
        border-radius: 200px;
        background: $inputColor;
        display: flex;
        align-items: center;
        font-family: Montserrat-Medium, sans-serif;
        font-size: 15px;
        cursor: pointer;
        position: relative;
        z-index: 100;

        &_item {
            height: $inputHeight;
            display: flex;
            padding: 0 20px;
            align-items: center;
            color: var(--main-dark-paper);

            &:hover {
                background: var(--main-paper);
                color: black;
            }

            & span {
                color: inherit;
            }

            &_label {
                width: 30px;
                height: 100%;
                display: flex;
                align-items: center;
                margin-right: 10px;

                & img {
                    height: 25px;
                    width: 25px;
                    object-fit: scale-down;
                }
            }
        }

        &_opened {
            border-radius: 10px 10px 0 0 !important;
        }

        &_list {
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            background: inherit;
            border-radius: 0 0 10px 10px;
            overflow: hidden;
        }

        &_body {
            width: 100%;
            padding: 0 23px;
            display: flex;
            align-items: center;

            & input {
                display: none !important;
                width: 100%;
                color: var(--main-dark-paper);
                background: none;
                border: none;
                outline: none;
                cursor: pointer;
            }
        }

        &_label {
            width: 45px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
                height: 30px;
                width: 35px;
                object-fit: scale-down;
            }
        }

        & svg {
            margin: 0 10px;
            font-size: 11px;
        }
    }
    .operation-modal {
        padding: 43px 0 52px 0;
        background: white;
        font-family: Montserrat-Medium, sans-serif;

        & iframe {
            width: 100%;
            border: none;
            height: 300px;
        }

        &_input {
            background: $inputColor;
            height: 46px;
            outline: none;
            border: none;
            width: 100%;
            border-radius: 200px;
            padding: 0 23px;
        }

        &_title {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 24px;
            color: var(--main-dark);
            text-align: center;
            margin-bottom: 36px;
        }

        &_body {
            width: 272px;
            margin: 0 auto;
        }
    }
</style>