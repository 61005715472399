<template>
    <div class="deal-slide" :class="className">
        <div class="deal-slide_header">
            <b-icon icon="dash" @click="$store.dispatch('exchanges.monitor')" />
        </div>
        <div class="deal-slide_body">
            <div class="deal-slide_timer">
                <p>{{ leftTimeStr }}</p>
                <radial-progress-bar
                    class="deal-slide_progress"
                    :inner-stroke-width="5"
                    :stroke-width="5"
                    stop-color="var(--main-app)"
                    start-color="var(--main-app)"
                    inner-stroke-color="#efefef"
                    :diameter="57"
                    :completed-steps="isActive ? slide.processing_duration - timer : 100"
                    :total-steps="isActive ? slide.processing_duration : 100">
                    <div class="deal-slide_progress_body">
                        <span>{{ percent }}%</span>
                    </div>
                </radial-progress-bar>
            </div>
            <div class="deal-slide_ps">
                <div class="deal-slide_ps_img">
                    <img :src="this.imageSelector(slide.ps_from.logo_url)" alt="">
                </div>
                <img v-if="slide.payout < 0" class="deal-slide_ps_arrow" src="/assets/img/red-arrow.png" alt="">
                <img v-if="slide.payout >= 0" class="deal-slide_ps_arrow" src="/assets/img/green-arrow.png" alt="">
                <div class="deal-slide_ps_img">
                    <img :src="this.imageSelector(slide.ps_to.logo_url)" alt="">
                </div>
            </div>
            <div class="deal-slide_payouts">
                <div :class="{'deal-slide_payouts_negative': slide.payout < 0}">{{ getPayoutStr(slide.payout) }}</div>
                <div>${{ slide.balance_after }}</div>
            </div>
            <div class="deal-slide_body_arrows" v-if="activeSlide === slide.number">
                <div>
                    <div class="deal-slide_body_arrows_item" @click="moveSlider('prev')" v-if="slide.number > 0">
                        <b-icon icon="chevron-left" />
                    </div>
                </div>
                <div>
                    <div class="deal-slide_body_arrows_item" @click="moveSlider('next')" v-if="!isActive">
                        <b-icon icon="chevron-right" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import RadialProgressBar from 'vue-radial-progress'

export default {
    name: "DealMonitorSlide",
    props: ['slide', 'activeChain', 'displayed', 'activeSlide'],
    components: {
        RadialProgressBar
    },
    data() {
        return {
            timer: null
        }
    },
    methods: {
        getPayoutStr(payout) {
            if (payout < 0) {
                return `-$${String(payout).replace('-', '')}`
            } else {
                return `+$${String(payout)}`
            }
        },
        moveSlider(direction) {
            this.$emit('move', direction)
        }
    },
    computed: {
        percent() {
            if (this.isActive) {
                const percent = Math.round((this.slide.processing_duration - this.timer) / this.slide.processing_duration * 100);

                if (percent > 100) {
                    return 100
                } else {
                    return Math.round((this.slide.processing_duration - this.timer) / this.slide.processing_duration * 100)
                }
            } else {
                return 100
            }
        },
        leftTimeStr() {
            if (this.isActive) {
                if (this.timer > 0) {
                    return moment.utc(this.timer*1000).format('HH:mm:ss')
                } else {
                    return moment.utc(this.slide.processing_duration*1000).format('HH:mm:ss')
                }
            } else {
                return moment.utc(this.slide.processing_duration*1000).format('HH:mm:ss')
            }
        },
        isActive () {
            return this.activeChain === this.slide.number
        },
        className() {
            let obj = {};
            let baseClassName = 'deal-slide';
            if (this.slide.number === this.activeSlide) {
                obj[baseClassName + '_active'] = true
            } else if (this.slide.number === this.activeSlide - 1) {
                obj[baseClassName + '_prev'] = true
            } else if (this.slide.number === this.activeSlide + 1) {
                obj[baseClassName + '_next'] = true
            } else if (this.slide.number > this.activeSlide) {
                obj[baseClassName + '_hidden_next'] = true
            } else if (this.activeSlide > this.slide.number) {
                obj[baseClassName + '_hidden_prev'] = true
            }

            return obj
        },
        ticker() {
            return this.$store.getters.ex_ticker
        }
    },
    watch: {
        ticker() {
            if (this.isActive && this.slide.time_left > 0) {
                this.timer = this.timer - 1;
            }
        },
        activeChain() {
            this.timer = Math.round(this.slide.time_left);
        }
    },
    created() {
        this.timer = Math.round(this.slide.time_left);
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .deal-slide {
        width: 350px;
        background: white;
        border-radius: 15px;
        position: absolute;
        height: 100%;
        transition: 0.3s;
        display: flex;
        flex-direction: column;

        @include only-xs {
            width: calc(100% - 15px);
        }

        &_progress {

            &_body {
                background: var(--main-app);
                height: 35px;
                width: 35px;
                border-radius: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: white;
                font-family: Montserrat-Light, sans-serif;
                font-weight: bold;
            }
        }

        &_payouts {
                margin-top: 54px;
                height: 73px;
                width: 100%;
                border-radius: 200px;
                background: rgba(120,216,34,0.10);
                display: flex;
                align-items: center;
                padding: 0 20px;

                &_negative {
                    color: red !important;
                }

                & div {
                    display: flex;
                    font-family: Montserrat-Light, sans-serif;


                    &:first-child {
                        font-size: 18px;
                        border-right: 1px solid var(--main-app);
                        padding-right: 15px;
                        color: silver;
                    }

                    &:last-child {
                        font-size: 25px;
                        padding-left: 15px;
                        font-weight: bold;
                        color: var(--main-app);
                    }
                }
            }

        &_body {
                width: 272px;
                margin: 0 auto;
                position: relative;

                @include only-xs-exchanges {
                    width: 100% !important;
                    padding: 0 15px;
                }

                &_arrows {
                    width: calc(100% + 220px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-30%);
                    left: -110px;
                    display: flex;
                    justify-content: space-between;

                    @include only-xs {
                        display: none;
                    }

                    &_item {
                        background: white;
                        width: 60px;
                        height: 60px;
                        border-radius: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.35);
                        cursor: pointer;

                        & svg {
                            transform: scale(1.75);
                            color: var(--main-dark);
                        }
                    }
                }
            }

        &_ps {
                height: 51px;
                margin-top: 47px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                &_img {
                    height: 100%;
                    width: 50px;
                    display: flex;
                    align-items: center;

                    & img {
                        height: auto;
                        width: 100%;
                    }
                }

                &_arrow {
                    height: 22px;
                }
            }

        &_timer {
                height: 75px;
                border: 2px solid #e4e4e4;
                margin-top: 3px;
                border-radius: 200px;
                padding: 0 13px 0 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                & p {
                    margin-bottom: 0;
                    font-size: 25px;
                    font-weight: bold;
                    font-family: Acrobat-Light, sans-serif;
                }
            }

        &_header {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding: 12px 18px;

            & svg {
                cursor: pointer;
            }
        }

        &_hidden {

                &_prev {
                    display: none;
                    left: 12%;
                    transform: translateX(-50%) scale(0.8);
                }

                &_next {
                    transform: translateX(-50%) scale(0.8);
                    display: none;
                    left: 88%;
                }
            }

        &_prev {
            display: flex !important;
            left: 31.6%;
            transform: translateX(-50%) scale(0.87);
            filter: blur(4px);
        }

        &_active {
            z-index: 10;
            display: flex;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0px -1px 7px rgba(0,0,0, 0.26);
        }

        &_next {
            display: flex !important;
            transform: translateX(-50%) scale(0.85);
            left: 68%;
            filter: blur(4px);
        }
    }
</style>